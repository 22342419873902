exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cadastro-tsx": () => import("./../../../src/pages/cadastro.tsx" /* webpackChunkName: "component---src-pages-cadastro-tsx" */),
  "component---src-pages-esqueci-a-senha-tsx": () => import("./../../../src/pages/esqueci-a-senha.tsx" /* webpackChunkName: "component---src-pages-esqueci-a-senha-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-politicas-de-privacidade-tsx": () => import("./../../../src/pages/politicas-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politicas-de-privacidade-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */)
}

